import {
  Box,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
  Image,
  SimpleGrid
} from '@chakra-ui/react'
import Spider from '../assets/images/spider.jpg';
import Rats from '../assets/images/rats.jpg';
import Cockroaches from '../assets/images/cockroaches.jpg';
import Bugs from '../assets/images/bugs.jpg';
import Bee from '../assets/images/bee.jpg';
import { Link } from 'react-router-dom';

interface CardProps {
  children: React.ReactNode;
  to: string;
}
interface Props {
  children: React.ReactNode;
}
const Card = (props: CardProps) => {
  const { children, to } = props;
  return (
    <Link to={to}>
      <Box 
        height="100%"
        cursor="pointer">
        {children}
      </Box>
    </Link>
  );
};

const CardContent = (props: Props) => {
  const { children } = props

  return (
    <Stack
      minH="100%"
      bg={useColorModeValue('white', 'gray.600')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _hover={{
        bg: useColorModeValue('whiteAlpha.300', 'gray.600'),
      }}
      >
      {children}
    </Stack>
  )
}

const CardHeading = (props: Props) => {
  const { children } = props

  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  )
}

const CardText = (props: Props) => {
  const { children } = props

  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  )
}
    
export default function Cards() {

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'} mt={50}>
          <Heading>NOS SERVICES</Heading>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2 , lg:3 }} spacing={10}>
          <Card to="/rats-et-souris">
            <CardContent>
              <Image 
                w={'100%'}
                rounded={'md'}
                h={200}
                alt={'feature image'}
                src={Rats}
                objectFit={'cover'}
              />
              <CardHeading>Rats et souris</CardHeading>
              <CardText>
              Ces rongeurs peuvent causer d'importants dommages matériels et propager 
              des maladies. Notre équipe est spécialisée dans la dératisation, 
              en utilisant des méthodes sûres et efficaces pour éliminer ces nuisibles de votre environnement.
              </CardText>
            </CardContent>
          </Card>
          <Card to="/cafards">
            <CardContent>
              <Image 
                w={'100%'} 
                rounded={'md'}
                h={200}
                alt={'feature image'}
                src={Cockroaches}
                objectFit={'cover'}
              />
              <CardHeading>Cafards</CardHeading>
              <CardText>
              Les cafards sont connus pour leur capacité à se reproduire 
              rapidement et à se faufiler dans les moindres recoins. Notre service de 
              désinsectisation cible ces insectes indésirables, 
              en utilisant des produits spécifiques pour les éliminer et prévenir leur retour.
              </CardText>
            </CardContent>
          </Card>
          <Card to="/punaises-de-lit">
            <CardContent>
              <Image 
                w={'100%'}
                rounded={'md'}
                h={200}
                alt={'feature image'}
                src={Bugs}
                objectFit={'cover'}
              />
              <CardHeading>Punaises de lit</CardHeading>
              <CardText>
              Les punaises de lit peuvent causer des problèmes de sommeil et d'irritation cutanée. 
              Notre équipe est formée pour identifier et traiter les infestations de punaises de lit,
               en utilisant des méthodes efficaces pour éliminer ces parasites de votre espace de vie.
              </CardText>
            </CardContent>
          </Card>
          <Card to="/guepes-et-frelons">
            <CardContent>
              <Image 
                w={'100%'}
                rounded={'md'}
                h={200}
                alt={'feature image'}
                src={Bee}
                objectFit={'cover'}
              />
              <CardHeading>Guêpes et frelons</CardHeading>
              <CardText>
              Les nids de guêpes et de frelons peuvent poser un danger pour vous et votre famille.
              Notre service de désinsectisation vous débarrasse de ces insectes volants, 
              en utilisant des méthodes sûres pour éliminer les nids et prévenir les piqûres.
              </CardText>
            </CardContent>
          </Card>
          <Card to="/araignees">
            <CardContent>
              <Image 
                w={'100%'}
                rounded={'md'}
                h={200}
                alt={'feature image'}
                src={Spider}
                objectFit={'cover'}
              />
              <CardHeading>Araignées et autres insectes</CardHeading>
              <CardText>
              Si vous êtes confronté à une infestation d'araignées ou d'autres insectes indésirables, notre équipe peut vous aider. 
              Nous utilisons des méthodes de désinsectisation adaptées pour éliminer ces nuisibles de votre environnement.
              </CardText>
            </CardContent>
          </Card>
          <Card to="/services">
            <CardContent>
              <Box 
                w={'100%'}
                rounded={'md'}
                h={200}
                backgroundColor={'black'}
                objectFit={'cover'}
              />
              <CardHeading>Autres</CardHeading>
              <CardText>
              N'hésitez pas à nous contacter pour toutes autres nuisibles.
              </CardText>
            </CardContent>
          </Card>
        </SimpleGrid>
      </Container>
    </Box>
  )
}