import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import LegalNotice from "../components/LegaleNotice";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
  <Box>
    <LegalNotice/>
  </Box>
  )
}