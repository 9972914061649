import {
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  useColorModeValue,
  Box,
  Link,
} from '@chakra-ui/react'
import Map from '../assets/images/map.png';
import { NavLink as RouterLink } from "react-router-dom";
import {ArrowForwardIcon} from '@chakra-ui/icons'

export default function SplitWithImage() {
  return (
    <Box  bg={useColorModeValue('gray.50', 'gray.700')} p={10}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
        <Stack spacing={4}>
          <Box textAlign={'center'} mb={5}>
          <Heading>NOS SERVICES</Heading>
          </Box>
          <Text fontSize={'lg'}>
          La lutte contre les nuisibles n’est pas seulement ce que nous faisons, 
          c’est aussi ce que nous sommes. Notre service fiable et durable de contrôle des parasites, 
          des rongeurs et de la faune est notre plus grande fierté à ce jour. Nous proposons des services 
          de lutte antiparasitaire de premier ordre pour les maisons et les entreprises de toute la région  
            <Text as="span" color="orange.500"> Rhône-Alpes.</Text>
        </Text>
        <Box textAlign={'center'}>
          <Link
          fontSize={'xl'}
          as={RouterLink}
          color='orange.500' 
          to="/services" 
          >
          En savoir plus <ArrowForwardIcon/>
          </Link>
        </Box>

        </Stack>
        <Flex>
          <Image
            border={'solid'}
            borderColor={useColorModeValue('gray.100', 'gray.700')}
            rounded={'md'}
            alt={'feature image'}
            src={Map}
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>
    </Box>
  )
}