import { Box, Container, Flex, Heading, Link, SimpleGrid, Stack, useColorModeValue, Image, Text} from "@chakra-ui/react";
import { useEffect } from "react";
import { ArrowBackIcon } from '@chakra-ui/icons'
import { NavLink as RouterLink } from "react-router-dom";
import ContactMe from '../components/ContactMe'
import BedBug from '../assets/images/bugs.jpg';

export default function BedBugPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={5}>
          <Box textAlign={'start'} ml={{sm:2,md:5,lg:10}} mt={5}>
            <Link
            fontSize={'xl'}
            as={RouterLink}
            color='orange.500' 
            to="/services" 
            >
            <ArrowBackIcon/>  Retour
            </Link>
          </Box>
          <Stack spacing={0} align={'center'} mt={1} mb={10}>
                <Heading>Désinsectisation et Traitement de punaises de lit</Heading>
          </Stack>
          <Stack spacing={0} align={'start'} mt={1} mb={10}>
            <Heading mb={5} size='lg'>Que sont les puces de lit ? :</Heading>
            <Text>
              Afin d’assurer un Traitement Punaises de lit efficace il est nécessaire de savoir ce que sont ces insectes.
              Les punaises de lit sont de petits insectes (environ la taille et la forme d’un petit pépin de pomme), bruns, rougeâtres qui existent depuis des milliers d’années. Ils se nourrissent exclusivement d’humains et d’animaux à sang chaud car ils ont besoin de sang pour se reproduire et pour survivre et certaines personnes peuvent être allergiques à leurs piqûres.
              Ces nuisibles ne peuvent heureusement ni voler ni sauter, mais ils sont capables de ramper très rapidement et grâce à leur toute petite taille, ils se cachent dans les fissures et les crevasses
              On les appelle alors punaises de lit en raison de leurs habitats préférés dans les maisons humaines : fissures de lits, canapés, matelas.
            </Text>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
          <Stack spacing={4}>
            <Box textAlign={'start'} >
            <Heading mb={5} size='lg'>les Punaises de lit en France : une énorme invasion de puces de lit !</Heading>
            </Box>
            <Text fontSize={'lg'}>
              Bien qu’on croyait les avoir éliminées depuis longtemps, les puces de lit ont brusquement réapparu dans les années quatre-vingt-dix. Contre toute attente, les punaises de lit n’ont pas de préférence entre un espace propre ou un environnement sale tant qu’elles disposent d’une source de nourriture. Tout milieu est ainsi favorable à leur existence propre qu’il soit ou sale. Les allégations selon lesquelles les punaises de lit sont attirées par la saleté et les débris ne sont donc  pas vraies. C’est bien ce qui fait alors leur propagation rapide.
              Chaque jour, les punaises de lit peuvent pondre entre un et 12 œufs, et entre 200 et 500 œufs au cours d’une vie. Une invasion se fait alors très rapidement et Il ne faut pas longtemps pour qu’un tel problème échappe à tout contrôle, donc le plus vite vous contacterez une entreprise anti puces de lit pour une inspection et un traitement Punaises de lits, le mieux vous vous en sortirez.
            </Text>
          </Stack>
          <Flex>
            <Image
              border={'solid'}
              borderColor={useColorModeValue('gray.100', 'gray.700')}
              rounded={'md'}
              alt={'feature image'}
              src={BedBug}
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
        <Stack spacing={0} align={'start'} mt={1} >
          <Heading mb={5} size='lg'>Traitement Punaises de Lit: comment les puces de lit se reproduisent?</Heading>
          <Text>
            Les punaises de lit prospèrent parce qu’elles se reproduisent rapidement et en grand nombre. il est si important alors de comprendre comment elles s’accouplent, où et comment elles pondent pour savoir comment les exterminer.
            Le mâle brise la coquille de la femelle pour injecter du sperme dans une cavité appelée hémocoele. Ce sperme circule ensuite dans le « système sanguin » de la punaise de lit jusqu’aux ovaires et fertilise les ovules. La femelle partira ensuite pour trouver un autre port et pondra des œufs pendant 6 à 8 semaines après s’être accouplée une seule fois.
            La reproduction de ces puces est alors assez rapide et ils peuvent ainsi envahir votre maison très rapidement. C’est pour cela, les produits utilisés par notre société anti puce de lit Fli-Tox empêchent la naissance de nouveaux parasites, d’où leurs efficacité.          
          </Text>
        </Stack>
        <Stack spacing={0} align={'start'}>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Devis Gratuit Et Rapide
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold"  }}>
            Appelez nous sur le <Link color={"orange.500"} textDecoration="underline" href="tel:+33 753833135">+33 7 53 83 31 35</Link> 7j/7 24h/24
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ça vous prend une minute ! <Link color={"orange.500"} as={RouterLink} to={'/contact'}>Nous contacter</Link>
          </Text>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ou bien vous souhaitez être rappelé par notre équipe rapidement ?
          </Text>
        </Stack>
        <Flex align={'center'} justify={'center'}>
            <ContactMe/>
        </Flex>
       </Container>
    </Box>
  )
}
