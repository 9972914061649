import CertificationCards from "../components/CertificationCards";
import Header from "../components/Header";
import ContactMeWithTitle from "../components/ContactMeWithTitle";
import Steps from "../components/Steps"
import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import Features from "../components/Features";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box>
      <Header/>
      <ContactMeWithTitle/>
      <Features/>
      <Steps/>
      <CertificationCards/>
    </Box>
  )
}