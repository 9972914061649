import { Box, Container, Heading, Stack, useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')} h={'100vh'}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'} mt={50}>
          <Heading>Page non trouvée</Heading>
        </Stack>
       </Container>
    </Box>
  )
}