import { Box, Container, Flex, Heading, Link, SimpleGrid, Stack, useColorModeValue, Image, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { ArrowBackIcon } from '@chakra-ui/icons'
import { NavLink as RouterLink } from "react-router-dom";
import ContactMe from '../components/ContactMe'
import Bee from '../assets/images/bee.jpg';

export default function WaspsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={5}>
          <Box textAlign={'start'} ml={{sm:2,md:5,lg:10}} mt={5}>
            <Link
            fontSize={'xl'}
            as={RouterLink}
            color='orange.500' 
            to="/services" 
            >
            <ArrowBackIcon/>  Retour
            </Link>
          </Box>
          <Stack spacing={0} align={'center'} mt={1} mb={10}>
                <Heading>Traitement contre les guêpes et frelons et destruction de nids</Heading>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
          <Stack spacing={4}>
            <Box textAlign={'start'} >
            <Heading mb={5} size='lg'>Comment être sûr qu’il s’agit de guêpes ou de frelons ? Comment les identifier ?</Heading>
            </Box>
            <Text fontSize={'lg'}>
              La guêpe et le frelon sont deux insectes volants de la famille des hyménoptères. Ils se nourrissent de fruits ou de déchets animaux, mais aussi d’insectes, comme des mouches ou des chenilles. Le frelon, en particulier le frelon asiatique, est quant à lui un redoutable prédateur des abeilles, ce qui est problématique.
              De couleur jaune et noire avec des bandes sur l’abdomen, la guêpe mesure de 1 à 2 cm, tandis que le frelon européen oscille entre 2 et 3. Insectes sociaux, ils ont pour habitude de construire des nids à proximité des habitations, dans les arbres ou au sol dans les jardins, près de leur source de nourriture.
              Le nid n’est parfois pas visible, car installé sous une toiture, ou très haut dans un arbre. On peut alors observer le ballet des insectes pour identifier une éventuelle entrée.
            </Text>
          </Stack>
          <Flex>
            <Image
              border={'solid'}
              borderColor={useColorModeValue('gray.100', 'gray.700')}
              rounded={'md'}
              alt={'feature image'}
              src={Bee}
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
        <Stack spacing={0} align={'start'} mt={1} mb={10}>
          <Heading mb={5} size='lg'>Pourquoi j’ai ce nuisible chez moi ?</Heading>
          <Text>
            Comme beaucoup d’insectes volants, leur présence est surtout importante l’été pour quasi disparaître au moment des premiers froids. Les premiers nids de printemps sont relativement modestes, mais grossissent à mesure que le temps passe pour atteindre des tailles parfois impressionnantes en septembre ou octobre. En revanche, si des nids sont parfois encore présents en hiver, ils sont bien souvent inactifs.
            La présence de guêpes ou de frelons est d’abord liée à des facteurs climatiques et régionaux. Néanmoins, on en trouve désormais sur l’ensemble du territoire national, et de plus en plus dans les grandes villes. Leur éradication y est plus compliquée car le nid est dans ce cas difficilement localisable.          
          </Text>
          <Heading my={5} size='lg'>Quels sont les risques ?</Heading>
          <Text>
            Le risque principal est de se faire piquer par ses insectes munis d’un dard empli de venin. Lorsqu’il se sent menacé, cet insecte peut piquer à plusieurs reprises, ce qui est à la fois douloureux et potentiellement dangereux : le venin s’accumule dans l’organisme, et les piqûres multiples peuvent être mortelles. En outre, certains individus y sont allergiques, sans toujours le savoir, et pour eux une seule piqûre peut être fatale (choc anaphylactique). Pour se prémunir de tels accidents, il est prudent de disposer d’un antihistaminique dans une trousse de premiers soins.
            Au-delà des risques pour l’homme, guêpes et frelons endommagent les arbres fruitiers ou les jeunes arbres forestiers, et sont une menace pour les abeilles, très utiles à l’équilibre de notre écosystème.
          </Text>
        </Stack>
        <Stack spacing={0} align={'start'} mt={1}>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Devis Gratuit Et Rapide
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold"  }}>
            Appelez nous sur le <Link color={"orange.500"} textDecoration="underline" href="tel:+33 753833135">+33 7 53 83 31 35</Link> 7j/7 24h/24
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ça vous prend une minute ! <Link color={"orange.500"} as={RouterLink} to={'/contact'}>Nous contacter</Link>
          </Text>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ou bien vous souhaitez être rappelé par notre équipe rapidement ?
          </Text>
        </Stack>
        <Flex align={'center'} justify={'center'}>
            <ContactMe/>
        </Flex>
       </Container>
    </Box>
  )
}
