import { Box, Container, Flex, Heading, Link, SimpleGrid, Stack, useColorModeValue, Image, Text, OrderedList, ListItem, Center, UnorderedList } from "@chakra-ui/react";
import { useEffect } from "react";
import { ArrowBackIcon } from '@chakra-ui/icons'
import { NavLink as RouterLink } from "react-router-dom";
import ContactMe from '../components/ContactMe'
import Spider from '../assets/images/spider.jpg';

export default function SpidersPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={5}>
          <Box textAlign={'start'} ml={{sm:2,md:5,lg:10}} mt={5}>
            <Link
            fontSize={'xl'}
            as={RouterLink}
            color='orange.500' 
            to="/services" 
            >
            <ArrowBackIcon/>  Retour
            </Link>
          </Box>
          <Stack spacing={0} align={'center'} mt={1} mb={10}>
                <Heading>Traitement pour lutter contre les araignées et autres insectes</Heading>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
          <Stack spacing={4}>
            <Box textAlign={'start'} >
            <Heading mb={5} size='lg'>
              Que sont les insectes ? 
            </Heading>
            </Box>
            <Text fontSize={'lg'}>
              Afin de comprendre leur importance et leur impact sur notre environnement, il est crucial de savoir ce que sont les insectes.
              <br />
              Les insectes constituent le groupe d'animaux le plus diversifié sur Terre, avec plus d'un million d'espèces décrites. Ils occupent une grande variété d'habitats et jouent un rôle crucial dans de nombreux écosystèmes.
            </Text>
            <UnorderedList>
              <ListItem>
               <Text as={'b'}>Pollinisation des plantes :</Text> Les abeilles, les papillons et d'autres insectes pollinisateurs contribuent à la reproduction des plantes, ce qui est essentiel pour la production de nourriture et la biodiversité.
              </ListItem>
              <ListItem>
                <Text as={'b'}>Décomposition de la matière organique :</Text> Les insectes comme les scarabées et les termites décomposent les feuilles mortes et autres débris, ce qui permet de recycler les nutriments dans le sol.
              </ListItem>
              <ListItem>
                <Text as={'b'}>Lutte contre les nuisibles :</Text> Certains insectes, comme les coccinelles, sont des prédateurs naturels d'autres insectes nuisibles, ce qui permet de réguler leurs populations.
              </ListItem>
              <ListItem>
                <Text as={'b'}>Source de nourriture :</Text> Les insectes sont une source de nourriture importante pour de nombreux animaux, y compris les oiseaux, les reptiles et les amphibiens.
              </ListItem>
            </UnorderedList>
          </Stack>
          <Flex>
            <Image
              border={'solid'}
              borderColor={useColorModeValue('gray.100', 'gray.700')}
              rounded={'md'}
              alt={'feature image'}
              src={Spider}
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
        
        <Stack spacing={0} align={'start'} mt={1}>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Devis Gratuit Et Rapide
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold"  }}>
            Appelez nous sur le <Link color={"orange.500"} textDecoration="underline" href="tel:+33 753833135">+33 7 53 83 31 35</Link> 7j/7 24h/24
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ça vous prend une minute ! <Link color={"orange.500"} as={RouterLink} to={'/contact'}>Nous contacter</Link>
          </Text>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ou bien vous souhaitez être rappelé par notre équipe rapidement ?
          </Text>
        </Stack>
        <Flex align={'center'} justify={'center'}>
            <ContactMe/>
        </Flex>
       </Container>
    </Box>
  )
}
