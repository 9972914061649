import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import Services from "../components/Services";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
  <Box>
    <Services/>
  </Box>
  )
}