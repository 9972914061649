import { Box, useColorModeValue, Stack, Container, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";

export default function About() {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
        <Container maxW={'7xl'} p={{base:2, md:5, lg:16}} as={Stack} >
            <Stack spacing={0} align={'center'} mt={{base:79,md:50,lg:50}} mb={10}>
                <Heading>QUI SOMMES-NOUS ?</Heading>
            </Stack>
            <Text>
            Chez FLI TOX, nous sommes passionnés par la lutte contre les nuisibles et la préservation
            d'un environnement sain. Nous sommes une équipe d'experts qualifiés et 
            dévoués, prêts à intervenir rapidement pour résoudre tous tes problèmes de nuisibles.
            </Text>
            <Text>
            Notre objectif est de fournir des services de dératisation, de désinsectisation et 
            de désinfection de qualité supérieure, en utilisant des méthodes respectueuses 
            de l'environnement et des produits sécuritaires pour les personnes et les animaux domestiques.
            </Text>
            <Text>
            Que ce soit pour ton domicile, ton entreprise ou tout autre lieu, 
            nous proposons des solutions personnalisées adaptées à tes besoins spécifiques. Notre équipe est formée pour identifier les problèmes, 
            mettre en place des stratégies efficaces et t'offrir des conseils pour prévenir de futures infestations.
            </Text>
            <Text>
            Nous sommes fiers de notre réputation d'excellence et
             de notre service client exceptionnel. La satisfaction de nos clients est notre priorité absolue. 
            </Text>
            <Text>
            Contactez-nous dès aujourd'hui pour en savoir plus sur nos services 
            et laissez-nous vous débarrasser de tous tes soucis de nuisibles !
            </Text>
            <Heading size={'lg'} >Nos zones d'intervention : </Heading>
            <UnorderedList>
              <ListItem>Ain</ListItem>
              <ListItem>Allier</ListItem>
              <ListItem>Ardèche</ListItem>
              <ListItem>Cantal</ListItem>
              <ListItem>Drôme</ListItem>
              <ListItem>Haute-Loire</ListItem>
              <ListItem>Haute-Savoie</ListItem>
              <ListItem>Isère</ListItem>
              <ListItem>Loire</ListItem>
              <ListItem>Puy-de-Dôme</ListItem>
              <ListItem>Rhône</ListItem>
              <ListItem>Savoie</ListItem>
              <ListItem>Métropole de Lyon</ListItem>
            </UnorderedList>
        </Container>
    </Box>
  )
}