import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import PrivacyPolicy from "../components/PrivacyPolicy";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
  <Box>
    <PrivacyPolicy/>
  </Box>
  )
}