import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Wrap,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useColorModeValue,
  Stack,
  chakra,
  VisuallyHidden,
  SimpleGrid,
  Select,
  HStack,
  Link,
} from '@chakra-ui/react'
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdOutlineEmail,
} from 'react-icons/md'
import { BsPerson } from 'react-icons/bs'
import { FaInstagram, FaTwitter, FaFacebook} from 'react-icons/fa'
import { ChangeEvent, FormEvent, ReactNode, useState } from 'react'
import { CheckIcon } from '@chakra-ui/icons'
import emailjs from "@emailjs/browser";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target="_blank"
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}
export default function Contact() {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('Rats et souris');
  const [userType, setUserType] = useState('Un particulier');
  const [phone, setPhone] = useState('');
  const [adress, setAdress] = useState('');
  const [state, setState] = useState<'initial' | 'submitting' | 'success'>('initial');
  const [error, setError] = useState(false);
  const googleMapsUrl = "https://www.google.fr/maps/place/Fli+tox+nuisible/@45.4333209,1.9836909,7z/data=!3m1!4b1!4m6!3m5!1s0x8f26731d1f407919:0x59e4fbbd3613a82c!8m2!3d45.4598898!4d4.6242221!16s%2Fg%2F11vj9b0v4m?entry=ttu";
  var templateParams = {
    message: message,
    user_email: email,
    user_name: name,
    type: type,
    userType: userType,
    phone: phone,
    adress: adress

  };

  const sendEmail = (e:any) => {
    e.preventDefault();
    emailjs
      .send(
        "service_iavvnyp",
        "template_jl9f2mt",
        templateParams,
        "_0TgZrQKWjzysbseN"
      )
      .then(
        () => {
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container bg={useColorModeValue('gray.50', 'gray.700')} maxW="full"  centerContent overflow="hidden" pt={'70px'}>
      <Flex>
        <Box
          bg={useColorModeValue('gray.200', 'gray.600')}
          borderRadius="lg"
          my={5}
          mx={{ sm: 4, md: 16, lg: 10 }}
          py={{ sm: 5, md: 5, lg: 1 }}
          px={{ sm: 5, md: 5, lg: 10 }}
          >
          <Box textAlign={'center'} >
            <Heading mt={5}>Nous contacter</Heading>
            <Text mt={{ sm: 3, md: 3, lg: 5 }} color={useColorModeValue('gray.500', 'gray.50')} >
              Remplissez le formulaire ci-dessous pour nous contacter
            </Text>
          </Box>
          <Box p={4}>
            <Wrap  bg={useColorModeValue('gray.50', 'gray.700')} p={{base:3, md:5, lg:5}} borderRadius="lg">
              <Stack 
              as={'form'}
              onSubmit={(e: FormEvent) => {
                e.preventDefault()

                setError(false)
                setState('submitting')
                setTimeout(() => {
                  if (!email || !message || !name) {
                    setError(true)
                    setState('initial')
                    return
                  }
                  sendEmail(e)
                  setState('success')
                }, 1000)
              }}>
              <SimpleGrid columns={{ base: 1, md: 2 , lg:2 }} spacing={{base:3, md:3, lg:5}}>
                <FormControl id="name">
                  <FormLabel>NOM / Prénom</FormLabel>
                  <InputGroup >
                    <InputLeftElement pointerEvents="none">
                      <BsPerson color="gray.800" />
                    </InputLeftElement>
                    <Input
                      variant={'solid'}
                      borderWidth={1}
                      bg={useColorModeValue('gray.50', 'gray.600')}
                      color={useColorModeValue('gray.800', 'white.700')}
                      _placeholder={{
                        color: 'gray.400',
                      }}
                      id={'name'}
                      name={'name'}
                      type={'text'}
                      required
                      placeholder={'NOM / Prénom'}
                      value={name}
                      disabled={state !== 'initial'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="email">
                  <FormLabel>E-mail</FormLabel>
                  <InputGroup >
                    <InputLeftElement pointerEvents="none">
                      <MdOutlineEmail color="gray.800" />
                    </InputLeftElement>
                    <Input
                      variant={'solid'}
                      bg={useColorModeValue('gray.50', 'gray.600')}
                      borderWidth={1}
                      color={useColorModeValue('gray.800', 'white.700')}
                      _placeholder={{
                        color: 'gray.400',
                      }}
                      id={'email'}
                      name={'email'}
                      type={'email'}
                      required
                      placeholder={'E-mail'}
                      value={email}
                      disabled={state !== 'initial'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="userType">
                  <FormLabel>Vous êtes ...</FormLabel>
                  <InputGroup >
                  <Select 
                      variant={'solid'}
                      borderWidth={1}
                      bg={useColorModeValue('gray.50', 'gray.600')}
                      color={useColorModeValue('gray.800', 'white.700')}
                      _placeholder={{
                        color: 'gray.400',
                      }}
                      id={'userType'}
                      name={'userType'}
                      required
                      value={userType}
                      disabled={state !== 'initial'}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => setUserType(e.target.value)}>
                      <option value='Un particulier'>Un particulier</option>
                      <option value='Un professionel'>Un professionel</option>
                    </Select>
                  </InputGroup>
                </FormControl>
                <FormControl id="phone">
                  <FormLabel>Téléphone</FormLabel>
                  <InputGroup >
                    <InputLeftElement pointerEvents="none">
                      <MdPhone color="gray.800" />
                    </InputLeftElement>
                    <Input
                      variant={'solid'}
                      borderWidth={1}
                      bg={useColorModeValue('gray.50', 'gray.600')}
                      color={useColorModeValue('gray.800', 'white.700')}
                      _placeholder={{
                        color: 'gray.400',
                      }}
                      id={'youAre'}
                      name={'youAre'}
                      type={'number'}
                      required
                      placeholder={'Téléphone'}
                      aria-label={'Téléphone'}
                      value={phone}
                      disabled={state !== 'initial'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="type">
                  <FormLabel>Type de nuisibles</FormLabel>
                  <InputGroup >
                    <Select 
                      variant={'solid'}
                      borderWidth={1}
                      bg={useColorModeValue('gray.50', 'gray.600')}
                      color={useColorModeValue('gray.800', 'white.700')}
                      _placeholder={{
                        color: 'gray.400',
                      }}
                      id={'type'}
                      name={'type'}
                      required
                      value={type}
                      disabled={state !== 'initial'}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => setType(e.target.value)}>
                      <option value='Rats et souris'>Rats et souris</option>
                      <option value='Cafards'>Cafards</option>
                      <option value='Punaises de lit'>Punaises de lit</option>
                      <option value='Guêpes et frelons'>Guêpes et frelons</option>
                      <option value='Araignées et autres insectes'>Araignées et autres insectes</option>
                      <option value='Autres'>Autres</option>
                    </Select>
                  </InputGroup>
                </FormControl>
                <FormControl id="adress">
                  <FormLabel>Ville / Code postal</FormLabel>
                  <InputGroup >
                    <InputLeftElement pointerEvents="none">
                      <MdLocationOn color="gray.800" />
                    </InputLeftElement>
                    <Input
                      variant={'solid'}
                      borderWidth={1}
                      bg={useColorModeValue('gray.50', 'gray.600')}
                      color={useColorModeValue('gray.800', 'white.700')}
                      _placeholder={{
                        color: 'gray.400',
                      }}
                      id={'adress'}
                      name={'adress'}
                      type={'text'}
                      required
                      placeholder={'Ville / Code postal'}
                      value={adress}
                      disabled={state !== 'initial'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setAdress(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </SimpleGrid>
              <FormControl id="message" >
                  <FormLabel>Décrivez-nous votre problème et la surface en m² concernée</FormLabel>
                  <Textarea
                    variant={'solid'}
                    bg={useColorModeValue('gray.50', 'gray.600')}
                    borderWidth={1}
                    color={useColorModeValue('gray.800', 'white.700')}
                    _placeholder={{
                      color: 'gray.400',
                    }}
                    id={'message'}
                    name={'message'}
                    required
                    placeholder="Décrivez-nous votre problème et la surface en m² concernée"
                    aria-label={'message'}
                    value={message}
                    disabled={state !== 'initial'}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
                  />
                </FormControl>
                <FormControl id="name" float="right"> 
                  <Button
                    colorScheme={state === 'success' ? 'green' : 'orange'}
                    bg={state === 'success' ? 'green' : 'orange.400'}
                    isLoading={state === 'submitting'}
                    _hover={{bg:'orange.500'}}
                    w="100%"
                    type={state === 'success' ? 'button' : 'submit'}>
                    {state === 'success' ? <CheckIcon /> : 'Envoyer !'}
                  </Button>
                </FormControl>
              </Stack>
              <Text mt={2} textAlign={'center'} color={error ? 'red.500' : 'gray.500'}>
              {error
                ? "Erreur ! Réessayer plus tard."
                : ""}
              </Text>
            </Wrap>
            <SimpleGrid columns={{ base: 1, md: 2 , lg:2 }} spacing={{base:3, md:3, lg:5}}>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                  <Stack align={'flex-start'}>
                    <Stack direction={'row'}>
                      <HStack _hover={{color:'orange.500'}}>
                        <MdPhone />
                        <Link
                        _hover={{color:'orange.500'}} 
                        textDecoration="underline" 
                        href="tel:+33 753833135"
                        isExternal
                        >
                          +33 7 53 83 31 35
                        </Link>
                      </HStack>
              
                      </Stack>
                      <Stack direction={'row'}>
                        <HStack _hover={{color:'orange.500'}}>
                          <MdEmail />
                          <Link
                          _hover={{color:'orange.500'}} 
                          textDecoration="underline" 
                          href={`mailto:${'contact@flitoxnuisible.fr'}`}
                          isExternal
                          >
                            contact@flitoxnuisible.fr
                          </Link>
                        </HStack>
                      </Stack>
                      <Stack direction={'row'}>
                        <HStack _hover={{color:'orange.500'}}>
                          <MdLocationOn />
                          <Link
                          textDecoration="underline" 
                          href={googleMapsUrl}
                          isExternal
                          >
                            Fli Tox Anti-Nuisible
                          </Link>
                        </HStack>
                      </Stack>
                    </Stack>
                  </Box>
                  <Stack direction={'row'}  mt={{ lg: 10, md: 10 }}
                    spacing={5}
                    px={5}
                    alignItems="flex-start">
                    <SocialButton label={'Twitter'} href={'#'}>
                      <FaTwitter />
                    </SocialButton>
                    <SocialButton label={'Facebook'} href={'https://www.facebook.com/profile.php?id=61552040687317&mibextid=ZbWKwL'}>
                      <FaFacebook />
                    </SocialButton>
                    <SocialButton label={'Instagram'} href={'#'}>
                      <FaInstagram />
                    </SocialButton>
                  </Stack>
            </SimpleGrid>
          </Box>
        </Box>
      </Flex>
    </Container>
  )
}