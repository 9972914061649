import { FormEvent, ChangeEvent, useState } from 'react'
import {
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Text,
  Container,
  Flex,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import emailjs from "@emailjs/browser";

export default function ContactMe() {
  const [number, setNumber] = useState('')
  const [state, setState] = useState<'initial' | 'submitting' | 'success'>('initial')
  const [error, setError] = useState(false)

  var templateParams = {
    message: number,
  };

  const sendEmail = (e:any) => {
    e.preventDefault();

    emailjs
      .send(
        "service_iavvnyp",
        "template_0skzb7f",
        templateParams,
        "_0TgZrQKWjzysbseN"
      )
      .then(
        () => {
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Flex
      zIndex={5}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container
        maxW={'lg'}
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        boxShadow={'xl'}
        rounded={'lg'}
        p={6}>
        
        <Stack
          direction={{ base: 'column', md: 'row' }}
          as={'form'}
          spacing={'12px'}
          onSubmit={(e: FormEvent) => {
            e.preventDefault()
            setError(false)
            setState('submitting')
            // submit logic right here
            setTimeout(() => {
              if (+number < 0 ) {
                setError(true)
                setState('initial')
                return
              }
              sendEmail(e)
              setState('success')
            }, 1000)
          }}>
          <FormControl>
            <Input
              variant={'solid'}
              borderWidth={1}
              bg={useColorModeValue('white.700', 'gray.600')}
              _placeholder={{
                color: 'gray.400',
              }}
              borderColor={useColorModeValue('gray.300', 'gray.600')}
              id={'message'}
              name={'message'}
              type={'number'}
              required
              placeholder={'Votre numéro'}
              aria-label={'Votre numéro'}
              value={number}
              disabled={state !== 'initial'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNumber(e.target.value)}
            />
          </FormControl>
          <FormControl w={{ base: '100%', md: '40%' }}>
            <Button
              colorScheme={state === 'success' ? 'green' : 'orange'}
              bg={state === 'success' ? 'green' : 'orange.400'}
              isLoading={state === 'submitting'}
              _hover={{bg:'orange.500'}}
              w="100%"
              type={state === 'success' ? 'button' : 'submit'}>
              {state === 'success' ? <CheckIcon /> : 'Rappelez moi !'}
            </Button>
          </FormControl>
        </Stack>
        <Text mt={2} textAlign={'center'} color={error ? 'red.500' : 'gray.500'}>
          {error
            ? "Erreur ! Réessayer plus tard."
            : ""}
        </Text>
      </Container>
    </Flex>
  )
}