import {
  Box,
  Heading,
  Link,
  Button,
  Stack,
  Flex,
} from '@chakra-ui/react'
import {
  MdPhone,
} from 'react-icons/md'
import Photo from '../assets/images/home.jpg';

export default function CallToActionWithAnnotation() {
      const backgroundImageStyle = {
        backgroundImage: `url(${Photo})`,
    };
    return (
        <Box
          pt="60px"
          w="full"
          h="85vh"
          bgRepeat="no-repeat"
          style={backgroundImageStyle}
          bgPos="center"
          bgSize="cover"
        >
          <Flex
            align="center"
            pos="relative"
            justify="center"
            boxSize="full"
            bg="blackAlpha.700"
          >
          <Box
            top="4%"
            left="0"
            fontWeight="bold"
            position="absolute"
            bg="red" 
            color="white" 
            p={2} 
            >
              Garantie 6 mois
            </Box>
            <Stack textAlign="center" alignItems="center" spacing={6}>
              <Heading
                as='h2' size='2xl'
                color="white"
              >
                APPELEZ LES MEILLEURS DES MEILLEURS !
              </Heading>
              <Flex
                flex={{ base: 1, md: 'auto' }}
                mx={{ base: 3 }}
                display={{ base: 'none', md: 'flex' }}
                >
                <Heading 
                fontSize="20px"
                fontWeight="20px" 
                color="white">
                Pour vous débarrasser de vos nuisibles ! Appelez-nous au{" "}
                <Link
                  color='orange.500'
                  textDecoration="underline"
                  href="tel:+33 753833135"
                >
                  +33 7 53 83 31 35
                </Link>{" "}
                pour un service rapide, ou remplissez le formulaire ci-dessous pour commencer votre inspection gratuite.
                </Heading>
              </Flex>
              <Flex
                flex={{ base: 1, md: 'auto' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Box width="100%" mb={{ base: 2, md: 0 }} display={{ base: 'flex', md: 'none' }}>
                  <Button
                    colorScheme='orange.500'
                    bg={'orange.500'}
                    _hover={{
                      bg: 'orange.600',
                    }}
                    textTransform="uppercase"
                    textColor="white"
                    rounded={"full"}
                    as={'a'}
                    href="tel:+33 753833135"
                    leftIcon={<MdPhone />}
                    width="100%"
                  >
                    +33 7 53 83 31 35
                  </Button>
                </Box>

                <Box width="100%" ml={{ base: 0, md: 2 }}>
                  <Button
                    colorScheme='orange.500'
                    bg={'orange.500'}
                    _hover={{
                      bg: 'orange.600',
                    }}
                    textTransform="uppercase"
                    textColor="white"
                    rounded={"full"}
                    px={6}
                    as={'a'}
                    href='/contact'
                    width="100%"
                  >
                    Nous Contacter !
                  </Button>
                </Box>
              </Flex>

            </Stack>
          </Flex>
        </Box>
    );
}
