import {
  Box,
  Heading,
  Text,
  Stack,
  Container,
  useColorModeValue,
  Image,
  SimpleGrid
} from '@chakra-ui/react'
import Certifbiocide from '../assets/images/certifbiocide.png';
import Certificat from '../assets/images/certificat.jpg';
import Cs3d from '../assets/images/cs3d.jpg';

interface Props {
  children: React.ReactNode
}

const Card = (props: Props) => {
  const { children } = props

  return <Box>{children}</Box>
}

const CardContent = (props: Props) => {
  const { children } = props

  return (
    <Stack
      minH="100%"
      bg={useColorModeValue('white', 'gray.600')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      >
      {children}
    </Stack>
  )
}

const CardHeading = (props: Props) => {
  const { children } = props

  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  )
}

const CardText = (props: Props) => {
  const { children } = props

  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  )
}

export default function Cards() {
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>NOS CERTIFICATIONS</Heading>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2 , lg:3 }} spacing={10}>
          <Card>
            <CardContent>
              <Image 
                  rounded={'md'}
                  h={200}
                  alt={'feature image'}
                  src={Certifbiocide}
                  objectFit={'cover'}
                />
              <CardHeading>Certibiocide</CardHeading>
              <CardText>
              AMA Nuisibles est une entreprise certifiée, détentrice du certibiocide qui est un 
              dispositif national qui vise à former les professionnels amenés à utiliser, 
              vendre ou acheter certains types de produits biocides destinés aux professionnels.
              </CardText>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
            <Image 
                  rounded={'md'}
                  h={200}
                  alt={'feature image'}
                  src={Certificat}
                  objectFit={'cover'}
                />
              <CardHeading>Certipunaises</CardHeading>
              <CardText>
              Véritable gage de qualité pour les traitements contre les punaises de lit. 
              La création de ce label est une clause de la convention signée entre la CS3D et le Ministère du Logement.
              </CardText>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
            <Image 
                  rounded={'md'}
                  h={200}
                  alt={'feature image'}
                  src={Cs3d}
                  objectFit={'cover'}
                />
              <CardHeading>Chambre Syndicale 3D</CardHeading>
              <CardText>
              Syndicale 3D réunit les principaux acteurs de l’hygiène antiparasitaire (protection contre les parasites et les nuisibles) :
              Applicateurs, fabricants, distributeurs et formateurs.
              </CardText>
            </CardContent>
          </Card>
        </SimpleGrid>
      </Container>
    </Box>
  )
}