import { Box, Container, Flex, Heading, Link, SimpleGrid, Stack, useColorModeValue, Image, Text, OrderedList, ListItem, UnorderedList } from "@chakra-ui/react";
import { useEffect } from "react";
import { ArrowBackIcon } from '@chakra-ui/icons'
import { NavLink as RouterLink } from "react-router-dom";
import ContactMe from '../components/ContactMe'
import Cockroaches from '../assets/images/cockroaches.jpg';

export default function CockroachesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={5}>
          <Box textAlign={'start'} ml={{sm:2,md:5,lg:10}} mt={5}>
            <Link
            fontSize={'xl'}
            as={RouterLink}
            color='orange.500' 
            to="/services" 
            >
            <ArrowBackIcon/>  Retour
            </Link>
          </Box>
          <Stack spacing={0} align={'center'} mt={1} mb={10}>
                <Heading>Désinsectisation des cafards</Heading>
          </Stack>
          <Stack spacing={0} align={'start'} mt={1} mb={10}>
            <Heading mb={5} size='lg'>Eradiquer les cafards: Pourquoi il y a des cafards dans ma maison ?</Heading>
            <Text>
            Avant d’essayer d’éradiquer les cafards, vous devez comprendre pourquoi ils sont apparus chez vous. 
            </Text>
            <UnorderedList>
              <ListItem>
                <Text as={'b'}>Propriétés de cafards</Text>
                <br />
                Les cafards préfèrent les endroits chauds et humides et sont des insectes nocturnes. En effet, ils se cachent dans des endroits isolés pendant la journée. On peut donc les détecter la nuit. Si vous allumez la lumière, vous verrez les cafards courir dans les fissures. Ces insectes sont aussi extrêmement dynamiques. C’est pourquoi, vous ne parviendrez parfois pas à les écraser.
              </ListItem>
              <ListItem>
                <Text as={'b'}>D’où viennent-ils?</Text>
                <br />
                Les cafards peuvent envahir votre maison de plusieurs façons. En effet, Ces nuisibles se rendent généralement à l’intérieur en rampant à travers les caves, les fenêtres ouvertes et même sous les portes.
                Ils peuvent également s’introduire à travers le lavabo de la salle de bain.  Il est bien connu, en fait, que les cafards se déplacent le long des canalisations des égouts passant par les conduites d’eaux et d’évacuation, arrivant à la maison à laquelle ils sont reliés. Mais, une fois à l’intérieur, ils se dirigent vers les cuisines à la recherche de nourriture.
              </ListItem>
          </UnorderedList>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
          <Stack spacing={4}>
            <Box textAlign={'start'} >
            <Heading mb={5} size='lg'>Comment prévenir une infestation de cafards?</Heading>
            </Box>
            <Text fontSize={'lg'}>
            Mieux vaut prévenir une infestation que d’essayer d’éradiquer les cafards après que l’infestation ait lieu. Suivez alors ces deux simples et efficaces étapes pour prévenir les infestations de cafards. 
            </Text>
            <UnorderedList>
              <ListItem>
                <Text as={'b'}>Garder votre maison propre :</Text>
                <br />
                Les cafards sont attirés par les résidus alimentaires, en particulier la graisse. c’est pourquoi il est nécessaire de s’assurer que votre logement est bien propre. Ainsi, vous ne devez jamais laisser votre nourriture ou les restes exposés et  assurez-vous qu’ils sont placés dans des récipients bien fermés. Il faut aussi essuyer les comptoirs chaque soir, ne jamais laisser de vaisselle sale dans l’évier, et balayer les sols avant d’aller au lit.              </ListItem>
              <ListItem>
              <Text as={'b'}>Sceller toutes les zones d’entrée :</Text>
                <br />
                Les cafards disposent d’un exosquelette incroyablement flexible. Ils sont capables ainsi d’aplatir leur corps avec aisance et de se faufiler très efficacement dans les trous les plus étroits. Que vous remarquez alors des crevasses entre vos armoires, vos plinthes, vos portes, vos murs, c’est une porte pour ces nuisibles ! Assurez-vous donc d’utiliser un joint pour sceller toutes ces zones. D’autre part, les cafards ne peuvent survivre que quelques jours sans eau. Fermez alors leurs sources d’eau en réparant la moindre fuite. Si vous trouvez donc une entrée, bouchez-la.
              </ListItem>
          </UnorderedList>
          </Stack>
          <Flex>
            <Image
              border={'solid'}
              borderColor={useColorModeValue('gray.100', 'gray.700')}
              rounded={'md'}
              alt={'feature image'}
              src={Cockroaches}
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
        <Stack spacing={0} align={'start'} mt={1} >
          <Heading mb={5} size='lg'>Astuces naturelles pour éradiquer les cafards : </Heading>
          <OrderedList>
            <ListItem>
              <Text as={'b'}>Suivez les méthode de prévention :</Text>
              <br />
            Les deux méthodes de prévention mentionnées dans le paragraphe précédent sont les premières mesures à prendre pour lutter contre une infestation de blattes. Ainsi, si ces mesures ne sont pas prises au sérieux, les méthodes suivantes ne seront ni efficaces ni de résultats durables.
            </ListItem>
            <ListItem>
              <Text as={'b'}>Eradiquer les cafards à l’aide d’un gel anti-cafards: :</Text>
              <br />
              A fin d’éradiquer les cafards, vous pouvez utiliser les gels qui contiennent des insecticides et un composant leurrant. Ces derniers, incitent les nuisibles à manger le gel, qu’ils prennent pour une source de nourriture, et consomment donc l’insecticide qu’il contient. D’autre part, le gel ne fonctionne malheureusement pas toujours comme prévu. Souvent, ces nuisibles ne consomment pas le gel et les infestations continuent à se développer. De plus, les appâts en gel sont inefficaces s’ils sont appliqués dans des endroits où les parasites ne les trouveront pas et s’ils ne sont pas régulièrement échangés car une fois secs, ces gels n’attirent plus les cafards.
            </ListItem>
            <ListItem>
              <Text as={'b'}>Eradiquer les cafards à l’aide des pièges :</Text>
              <br />
              Exemple: avec de l’acide borique.
              Comme les cafards meurent peu après avoir ingéré de l’acide borique, cette poudre peut être utilisée comme appât. Commencez alors par vider les armoires de votre cuisine et la zone sous l’évier. Répandez ensuite l’acide borique dans toute la cuisine. Faites attention à ne pas en inhaler lorsque vous l’appliquez.
              Pour de meilleurs résultats, appliquez l’acide borique le soir et laissez-le agir jusqu’au matin et veillez à rester loin de la pièce!
            </ListItem>
            <ListItem>
              <Text as={'b'}>Poussez les cafards à quitter votre maison :</Text>
              <br />
              Exemple: avec du vinaigre blanc.
              Bien que la plupart des gens pensent que le vinaigre tue les cafards, ce n’est pas le cas. Il est plutôt utilisé comme produit de nettoyage, ce qui incite les cafards à chercher refuge ailleurs.Les blattes se réfugient dans une zone non assainie, où la nourriture et l’eau sont abondantes. Ainsi, le nettoyage à lui seul permettra d’éradiquer une grande partie de cette infestation de cafards.
            </ListItem>
          </OrderedList>
          <Heading my={5} size='lg'>Attention à l’usage des insecticides !!!</Heading>
          <Text>
          Les gels, les insecticides à pulvériser, ainsi que les pesticides en poudre sont faits de produits chimiques et doivent être utilisés avec prudence. Ainsi, n’utilisez jamais un insecticide contre les cafards qui n’est pas accompagné d’instructions claires. Suivez aussi attentivement les consignes de sécurité figurant sur l’étiquette. Il faut également porter une attention particulière aux produits vendus en ligne. Le non-respect des instructions peut entraîner un mauvais contrôle. Protégez alors votre famille en suivant les indications avec soin.
          </Text>
        </Stack>
        <Stack spacing={0} align={'start'} mt={1}>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Devis Gratuit Et Rapide
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold"  }}>
            Appelez nous sur le <Link color={"orange.500"} textDecoration="underline" href="tel:+33 753833135">+33 7 53 83 31 35</Link> 7j/7 24h/24
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ça vous prend une minute ! <Link color={"orange.500"} as={RouterLink} to={'/contact'}>Nous contacter</Link>
          </Text>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ou bien vous souhaitez être rappelé par notre équipe rapidement ?
          </Text>
        </Stack>
        <Flex align={'center'} justify={'center'}>
            <ContactMe/>
        </Flex>
       </Container>
    </Box>
  )
}
