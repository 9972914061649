import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  Link,
  Image
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MoonIcon,
  SunIcon
} from '@chakra-ui/icons'
import {NavLink as RouterLink} from "react-router-dom";
import LightLogo from '../assets/logos/logo-light.png';
import DarkLogo from '../assets/logos/logo-dark.png';

export default function WithSubnavigation() {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { colorMode, toggleColorMode } = useColorMode();
  const Logo = colorMode === "light" ? LightLogo : DarkLogo;
  return (
    <Box>
      <Flex
        position="fixed"
        w="100%"
        zIndex={10}
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Image src={Logo} height={10}></Image>
          
          <Flex display={{ base: 'none', md: 'flex' }} ml={5} mt={1}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Button
            onClick={toggleColorMode}
          >
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onClose} />
      </Collapse>
    </Box>
  )
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                as={RouterLink}
                to={navItem.href ?? '#'}
                _activeLink={{ fontWeight: "bold" , color:linkHoverColor}}
                p={2}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  const linkHoverColor = useColorModeValue('gray.800', 'orange.400')
  return (
    <Link
      as={RouterLink}
      to={href ?? '#'}
      _activeLink={{ fontWeight: "bold" , color: linkHoverColor}}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('orange.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'orange.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'orange.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  )
}

const MobileNav = ({ onClose }: any & { onClose: () => void }) => {

  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }} pt={'60px'}  
    position="fixed"
    w="100%"
    zIndex={9}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} onClose={onClose} {...navItem} />
      ))}
    </Stack>
  );
};

// Inside MobileNavItem
const MobileNavItem = ({ label, children, href, onClose }: NavItem & { onClose: () => void }) => {
  const { isOpen, onToggle } = useDisclosure();
  const linkActiveColor = useColorModeValue('gray.800', 'white');



  return (
    <Stack spacing={4} onClick={children ? onToggle : onClose}>
      <Box
        py={2}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}>
        <Link
          as={RouterLink}
          to={href}
          _activeLink={{ fontWeight: "bold" , color: linkActiveColor}}
          p={2}
          color={useColorModeValue('gray.600', 'gray.200')}
          fontSize={'sm'}
          fontWeight={500}
          _hover={{
            textDecoration: 'none',
          }}
        >
          {label}
        </Link>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link           
                _activeLink={{ fontWeight: "bold" , color: linkActiveColor}} 
                as={RouterLink} 
                key={child.label} 
                py={2} 
                to={child.href} 
                onClick={onClose}
                _hover={{
                  textDecoration: 'none',
                }}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'ACCUEIL',
    href: '/',
  },
  {
    label: 'SERVICES ',
    children: [
      {
        label: 'Nos services',
        href: '/services',
      },
      {
        label: 'Dératisation des souris et des rats',
        href: '/rats-et-souris',
      },
      {
        label: 'Désinsectisation des cafards',
        href: '/cafards',
      },
      {
        label: 'Désinsectisation et Traitement de punaises de lit',
        href: '/punaises-de-lit',
      },
      {
        label: 'Traitement contre les guêpes et frelons',
        href: '/guepes-et-frelons',
      },
      {
        label: 'Traitement pour lutter contre les araignées et autres insectes',
        href: '/araignees',
      },
    ],
  },
  {
    label: 'QUI SOMME-NOUS?',
    href: '/about',
  },
  {
    label: 'NOUS CONTACTER',
    href: '/contact',
  }
]