import Contact from "../components/Contact";
import { Box } from "@chakra-ui/react";
import { useEffect } from 'react';

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box>
        <Contact/>
    </Box>
  )
}