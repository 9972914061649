import { Box, Container, Flex, Heading, Link, SimpleGrid, Stack, useColorModeValue, Image, Text, OrderedList, ListItem } from "@chakra-ui/react";
import { useEffect } from "react";
import { ArrowBackIcon } from '@chakra-ui/icons'
import { NavLink as RouterLink } from "react-router-dom";
import ContactMe from '../components/ContactMe'
import Rats from '../assets/images/rats.jpg';

export default function RatsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={5}>
          <Box textAlign={'start'} ml={{sm:2,md:5,lg:10}} mt={5}>
            <Link
            fontSize={'xl'}
            as={RouterLink}
            color='orange.500' 
            to="/services" 
            >
            <ArrowBackIcon/>  Retour
            </Link>
          </Box>
          <Stack spacing={0} align={'center'} mt={1} mb={10}>
                <Heading>Dératisation des souris et des rats</Heading>
          </Stack>
          <Stack spacing={0} align={'start'} mt={1} mb={10}>
            <Heading mb={5} size='lg'>Comment savoir si vous avez un rongeur dans la maison ?</Heading>
            <Text>
              Les rongeurs varient des souris et des rats aux martres ou à d’autres espèces. Ce n’est pas parce que vous n’avez pas vu de rongeur qu’il n’est pas là chez vous ou dans votre boulangerie. Si vous soupçonnez que vous avez une souris dans la maison, vous pourriez avoir raison si vous voyez l’une de ces 2 preuves les plus communes et la il vous faut donc une Dératisation des souris :
              Des déjections de souris : Ces nuisibles laissent souvent des preuves évidentes de leur existence dans les pièces où la nourriture est conservée, le long des plinthes et sous les lavabos.
              Des traces de mâche : En cherchant de la nourriture et de l’eau, les rongeurs peuvent mâcher un grand nombre de matériaux un peu partout.
            </Text>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} w={'100%'}>
          <Stack spacing={4}>
            <Box textAlign={'start'} >
            <Heading mb={5} size='lg'>Les dangers sanitaires des souris et des rats</Heading>
            </Box>
            <Text fontSize={'lg'}>
            Nous trouvions tout bizarre d’avoir des souris et des rats dans nos maisons, mais sont-ils vraiment dangereux ? Malheureusement, oui les souris et les rats peuvent être très dangereux.
            Les rongeurs peuvent être porteurs d’un certain nombre de maladies et ils peuvent ramasser des particules d’aliments contaminés et transmettre donc de nombreuses maladies comme l’hantavirus et la fièvre hémorragique.
            </Text>
          </Stack>
          <Flex>
            <Image
              border={'solid'}
              borderColor={useColorModeValue('gray.100', 'gray.700')}
              rounded={'md'}
              alt={'feature image'}
              src={Rats}
              objectFit={'cover'}
            />
          </Flex>
        </SimpleGrid>
        <Stack spacing={0} align={'start'} mt={1} mb={10}>
          <Heading mb={5} size='lg'>Comment se débarrasser des souris et des rats :</Heading>
          <OrderedList>
            <ListItem>Gardez bien la nourriture loin des souris :
              Les souris ont besoin d’un endroit agréable et chaleureux pour vivre. Ils préfèrent généralement les restaurants et les boulangeries, mais votre maison et surtout votre cuisine feront l’affaire comme abri. Ils n’ont aussi besoin que d’un peu de nourriture, la teneur hydrique des fruits et du pain est suffisante. Assurez-vous d’entreposer vos grains et céréales dans des contenants scellés et placez-les dans les tiroirs les plus hauts dont vous disposez. Vous devriez également conserver vos fruits, pommes de terre et autres aliments périssables dans votre réfrigérateur. Balayez ou passez l’aspirateur pour ramasser les miettes et gardez le couvercle de votre poubelle bien fermé.</ListItem>
            <ListItem>Évitez l’encombrement des sous-sols et des greniers.</ListItem>
            <ListItem>Scellez toutes les entrées possibles:
              Lorsqu’il fait froid en hiver, il devrait être facile pour vous de vous déplacer dans la maison et de vérifier s’il y a de l’air qui entre par de petits trous ou des fissures. Les souris peuvent passer à travers de très petits trous (si la tête peut passer à travers, le corps le peut). Calfeutrer, barricader ou enfoncer de la laine d’acier dans les ouvertures pour empêcher les souris de revenir dans la maison.</ListItem>
          </OrderedList>
          <Text>
            Une dératisation efficace consiste d’abord à éliminer la population de rongeurs, les rats et les souris , par la pose d’appâts rodonticides (raticides), puis après un examen sérieux de la situation, éviter une nouvelle invasion en obturant par les accès possibles.
          </Text>
        </Stack>
        <Stack spacing={0} align={'start'} mt={1}>
          
          <Heading mb={5} size='lg'>Combien coûte une dératisation</Heading>
          <Text>
          Dites-nous quelle est la taille de votre appartement, combien de pièces il a et où vous habitez et nous vous dirons exactement combien coûte une dératisation de souris et de rats dans environ 30 minutes.
          </Text>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Devis Gratuit Et Rapide
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold"  }}>
            Appelez nous sur le <Link color={"orange.500"} textDecoration="underline" href="tel:+33 753833135">+33 7 53 83 31 35</Link> 7j/7 24h/24
          </Text>
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ça vous prend une minute ! <Link color={"orange.500"} as={RouterLink} to={'/contact'}>Nous contacter</Link>
          </Text>
          <br />
          <Text as="p" style={{ fontStyle: "italic", fontWeight: "bold" }}>
            Ou bien vous souhaitez être rappelé par notre équipe rapidement ?
          </Text>
        </Stack>
        <Flex align={'center'} justify={'center'}>
            <ContactMe/>
        </Flex>
       </Container>
    </Box>
  )
}
