import { ReactElement } from 'react'
import { Box, SimpleGrid, Icon, Text, Stack, Flex, Heading, useColorModeValue } from '@chakra-ui/react'
import { IoCheckmarkCircleSharp , IoWalletSharp , IoListCircle , IoChatbubbles } from 'react-icons/io5'

interface FeatureProps {
  title: string
  text: string
  icon: ReactElement
  iconBg: string
}

const Feature = ({ title, text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack textAlign={'center'} align={'center'}>
        <Flex
        bg={iconBg}
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        mb={1}>
        {icon}
        </Flex>
        <Text fontWeight={600}>{title}</Text>
      <Text color={useColorModeValue('gray.600', 'gray.400')}>{text}</Text>
    </Stack>
  )
}

export default function SimpleThreeColumns() {
  return (
    <Box p={4}  bg={useColorModeValue('gray.50', 'gray.700')}>
      <Stack spacing={0} align={'center'} m={10} mb={20}>
        <Heading>NOS PROCÉDURES D'INTERVENTION</Heading>
      </Stack>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
        <Feature
          iconBg={useColorModeValue('yellow.100', 'yellow.900')}
          icon={<Icon as={IoChatbubbles} color={'yellow.500'} w={10} h={10} />}
          title={'Votre demande sur le site'}
          text={
           "Vous constatez la présence de nuisibles chez vous ? N'attendez pas ! prenez contact avec FLI TOX."
          }
        />
        <Feature
          iconBg={useColorModeValue('orange.100', 'orange.900')}
          icon={<Icon as={IoListCircle} color={'orange.500'} w={10} h={10} />}
          title={'Vous êtes recontacté'}
          text={
            "Nous établissons un premier diagnostic par téléphone afin d’organiser l’intervention"
          }
        />
        <Feature
          iconBg={useColorModeValue('green.100', 'green.900')}
          icon={<Icon as={IoCheckmarkCircleSharp} color={'green.500'} w={10} h={10} />}
          title={"L'intervention est réalisée"}
          text={
            "Nos techniciens sont des professionnels expérimentés qui vous expliquent le traitement mis en œuvre et vous apportent leurs meilleurs conseils pour éviter toute nouvelle intrusion de nuisibles dans votre domicile."
          }
        />
        <Feature
          iconBg={useColorModeValue('blue.100', 'blue.900')}
          icon={<Icon as={IoWalletSharp} color={'blue.500'} w={10} h={10} />}
          title={"Règlement simple et rapide"}
          text={
            "Vous réglez votre intervention par carte bancaire, par chèque, ou en espèces. Un reçu CB et une facture vous sont envoyés par mail."          }
        />
      </SimpleGrid>
    </Box>
  )
}