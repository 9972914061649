import {
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import ContactMe from './ContactMe'
export default function SplitScreen() {
  return (
    <Stack textAlign={'center'} bg={useColorModeValue('gray.50', 'gray.700')}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={6} w={'full'} maxW={'lg'}>
          <Heading >
              UNE URGENCE ?
          </Heading>
          <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
            Vous souhaitez être
            rappelé par notre équipe rapidement ?
          </Text>
          <Stack spacing={4}>
            <ContactMe/>
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  )
}