import {
  Box,
  chakra,
  Image,
  Container,
  SimpleGrid,
  Text,
  VisuallyHidden,
  useColorModeValue,
  useColorMode,
  Stack,
  HStack,
  Link,
  Flex,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FaInstagram, FaTwitter, FaFacebook} from 'react-icons/fa'
import LightLogo from '../assets/logos/logo-light.png';
import DarkLogo from '../assets/logos/logo-dark.png';
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
} from 'react-icons/md'
import { NavLink as RouterLink } from "react-router-dom";
import Warranty from '../assets/images/waranty.png';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target="_blank"
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  )
}

export default function Footer() {
  const { colorMode } = useColorMode();
  const Logo = colorMode === "light" ? LightLogo : DarkLogo;
  const iframeSrc = colorMode ==="light" ? '25390474' : '25390485'
  const googleMapsUrl = "https://www.google.fr/maps/place/Fli+tox+nuisible/@45.4333209,1.9836909,7z/data=!3m1!4b1!4m6!3m5!1s0x8f26731d1f407919:0x59e4fbbd3613a82c!8m2!3d45.4598898!4d4.6242221!16s%2Fg%2F11vj9b0v4m?entry=ttu";
  return (
    <Box
      bg={useColorModeValue('gray.100', 'gray.800')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
          spacing={8}
          >
          <Stack spacing={6}>
            <Flex align={'center'} justify={'center'}>
              <Image src={Logo} height={10}></Image>
            </Flex>
            <Flex align={'center'} justify={'center'}>
              <Text fontSize={'sm'}>Droits d'auteur © 2023 Tous droits réservés</Text>
            </Flex>
            <Flex align={'center'} justify={'center'}>
              <Stack direction={'row'} spacing={6}>
                <SocialButton label={'Twitter'} href={'#'}>
                  <FaTwitter  />
                </SocialButton>
                <SocialButton label={'Facebook'} href={'https://www.facebook.com/profile.php?id=61552040687317&mibextid=ZbWKwL'}>
                  <FaFacebook />
                </SocialButton>
                <SocialButton label={'Instagram'} href={'#'}>
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Flex>
            <Flex align={'center'} justify={'center'}>
              <Image
                h={'150px'}
                w={'150px'}
                border={'solid'}
                borderColor={useColorModeValue('gray.100', 'gray.800')}
                rounded={'md'}
                alt={'warranty image'}
                src={Warranty}
                objectFit={'cover'}
              />
            </Flex>
              
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Plan du site</ListHeader>
            <Link _hover={{color:'orange.500'}} as={RouterLink} to={'/services'}>
              Nos services
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/about'}>
              Qui sommes-nous?
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/contact'}>
              Nous contacter
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/rats-et-souris'}>
              Rats et souris
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/cafards'}>
              Cafards
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/punaises-de-lit'}>
              Punaises de lit
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/guepes-et-frelons'}>
              Guêpes et frelons
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/araignees'}>
              Araignées et autres insectes
            </Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'privacy-policy'}>
              Politique de Confidentialité    
            </Link>
            <Link _hover={{color:'orange.500'}}  as={RouterLink} to={'/legal-notice'}>
              Mentions légales
            </Link>
            <ListHeader>Contact</ListHeader>
            <Stack direction={'row'}>
              <HStack _hover={{color:'orange.500'}}>
                <MdPhone />
                <Link
                _hover={{color:'orange.500'}} 
                textDecoration="underline" 
                href="tel:+33 753833135"
                isExternal
                >
                  +33 7 53 83 31 35
                </Link>
              </HStack>
              
            </Stack>
            <Stack direction={'row'}>
              <HStack _hover={{color:'orange.500'}}>
                <MdEmail />
                <Link
                _hover={{color:'orange.500'}} 
                textDecoration="underline" 
                href={`mailto:${'contact@flitoxnuisible.fr'}`}
                isExternal
                >
                  contact@flitoxnuisible.fr
                </Link>
              </HStack>
            </Stack>
            <Stack direction={'row'}>
              <HStack _hover={{color:'orange.500'}}>
                <MdLocationOn />
                <Link
                textDecoration="underline" 
                href={googleMapsUrl}
                isExternal
                >
                  Fli Tox Anti-Nuisible
                </Link>
              </HStack>
            </Stack>
              
          </Stack>

          <Stack align={'flex-start'} border={'solid'} borderColor={useColorModeValue('gray.100', 'gray.700')}>
            <iframe src={`https://widgets.sociablekit.com/google-reviews/iframe/${iframeSrc}`} scrolling="no" width='100%' height='325px'></iframe>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  )
}